import { createSlice } from '@reduxjs/toolkit'

const initialShowChatState = {
    showChat: false,
};

export const ShowChatStateSlice = createSlice({
    name: 'ShowChat',
    initialState: initialShowChatState,
    reducers: {
        setShowChat: (state, action) => {
            state.showChat = action.payload;
        },

        resetShowChat: (state) => {
            state.showChat = false;
        }
    },
});

export const { setShowChat,resetShowChat} = ShowChatStateSlice.actions;
export default ShowChatStateSlice.reducer;
