import { createSlice } from '@reduxjs/toolkit'

const initialChatContextState = {
    chatContext: {},
};

export const ChatContextStateSlice = createSlice({
    name: 'ChatContext',
    initialState: initialChatContextState,
    reducers: {
        setChatContext: (state, action) => {
            state.chatContext = action.payload;
        },

        resetChatContext: (state) => {
            state.chatContext = {};
        }
    },
});

export const { setChatContext,resetChatContext} = ChatContextStateSlice.actions;
export default ChatContextStateSlice.reducer;
