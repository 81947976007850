import { createSlice } from '@reduxjs/toolkit'

const initialSearchTextState = {
    text: '',
};

export const SearchTextStateSlice = createSlice({
    name: 'SearchText',
    initialState: initialSearchTextState,
    reducers: {
        setSearchText: (state, action) => {
            state.text = action.payload;
        },

        resetSearchText: (state) => {
            console.log('reset searchtext',);
            state.text = '';
        }
    },
});

export const { setSearchText,resetSearchText} = SearchTextStateSlice.actions;
export default SearchTextStateSlice.reducer;
