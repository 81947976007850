import { createSlice } from '@reduxjs/toolkit'

const initialCustomerDetailsState = {
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    roomTypes: [],
    noOfRooms: '',
};

export const MakeReservationStateSlice = createSlice({
    name: 'MakeReservation',
    initialState: initialCustomerDetailsState,
    reducers: {
        setCustomerDetails: (state, action) => {
            console.log('action.payload', action.payload);
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.telephone = action.payload.telephone;
            state.roomTypes = action.payload.roomTypes;
            state.noOfRooms = action.payload.noOfRooms;
        },
        resetCustomerDetails: (state) => {
            state.firstName = '';
            state.lastName = '';
            state.email = '';
            state.telephone = '';
            state.roomTypes = [];
            state.noOfRooms = '';
        }
    }, 
});

export const { setCustomerDetails} = MakeReservationStateSlice.actions;
export default MakeReservationStateSlice.reducer;
