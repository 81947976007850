import { Web3Auth } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { CHAIN_NAMESPACES, UX_MODE, WEB3AUTH_NETWORK } from "@web3auth/base";
import { XrplPrivateKeyProvider } from "@web3auth/xrpl-provider";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import {
  LocalStorageKeys,
  LoginMethods,
  DestinationTags,
  TransactionTypes,
  PaymentResults,
} from "../constants/constants";
import {
  getAccountNFTsFromLedger,
  getAllAccountNFTs,
  showPayQRWindow,
  xummLogout,
} from "./xumm-api-service";
import { store } from "../redux/store";
import { logoutSuccessfully } from "../redux/LoginState/LoginStateSlice";
import CustomXrplAdapter from "../helpers/walletAdapters/XRPLAdapter";
import toast from "react-hot-toast";
import { uploadJsonToFileBase } from "../helpers/uploadToFileBase";
import { convertStringToHex } from "xrpl";
import NFTService from "../services-domain/nft-service";
import { xrpToDrops } from "xrpl";

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENTID;

// XRPL configs
const xrplWSUrl = process.env.REACT_APP_XRPL_WS_URL;
const xrplHTTPUrl = process.env.REACT_APP_XRPL_HTTP_URL;
const xrplChainId = process.env.REACT_APP_XRPL_CHAIN_ID;
const xrplBlockExploreUrl = process.env.REACT_APP_XRPL_BLOCK_EXPLORE_URL;

// SOLANA configs
const solanaWSUrl = process.env.REACT_APP_SOLANA_WS_URL;
const solanaHTTPUrl = process.env.REACT_APP_SOLANA_HTTP_URL;
const solanaChainId = process.env.REACT_APP_SOLANA_CHAIN_ID;
const solanaBlockExploreUrl = process.env.REACT_APP_SOLANA_BLOCK_EXPLORE_URL;

// SEPOLIA configs
const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
const sepoliaWSUrl = process.env.REACT_APP_SEPOLIA_WS_URL;
const sepoliaHTTPUrl = process.env.REACT_APP_SEPOLIA_HTTP_URL;
const sepoliaChainId = process.env.REACT_APP_SEPOLIA_CHAIN_ID;
const sepoliaBlockExploreUrl = process.env.REACT_APP_SEPOLIA_BLOCK_EXPLORE_URL;

const xrpl = require("xrpl");
const xrplClient = new xrpl.Client(xrplWSUrl);

// Client connection
const connectClient = async () => {
  try {
    await xrplClient.connect();
    console.log("Connected to XRPL WebSocket client");
  } catch (error) {
    console.error("Error connecting to XRPL WebSocket client:", error);
    toast.error("Failed to connect to XRPL WebSocket client");
  }
};
connectClient();

const xrplChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.XRPL,
  chainId: xrplChainId,
  rpcTarget: xrplHTTPUrl,
  wsTarget: xrplWSUrl,
  ticker: "XRP",
  tickerName: "XRPL",
  displayName: "XRPL",
  blockExplorerUrl: xrplBlockExploreUrl,
};

const sepoliaChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: sepoliaChainId,
  rpcTarget: `${sepoliaHTTPUrl}${infuraProjectId}`,
  wsTarget: `${sepoliaWSUrl}${infuraProjectId}`,
  displayName: "SEPOLIA",
  blockExplorerUrl: sepoliaBlockExploreUrl,
  ticker: "ETH",
  tickerName: "Ethereum",
};

const solanaChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.SOLANA,
  chainId: solanaChainId, // Use 'devnet' for Solana testnet
  rpcTarget: solanaHTTPUrl,
  wsTarget: solanaWSUrl,
  ticker: "SOL",
  tickerName: "Solana",
  displayName: "SOLANA",
  blockExplorerUrl: solanaBlockExploreUrl,
};

let web3auth = null;
let privateKeyProvider = null;

export const initWeb3Auth = async (loginMethod) => {
  try {
    console.log("Initializing Web3Auth...");

    // Creating private key providers
    if (
      loginMethod === LoginMethods.XRPL ||
      loginMethod === LoginMethods.SOCIAL
    ) {
      privateKeyProvider = new XrplPrivateKeyProvider({
        config: {
          chainConfig: xrplChainConfig,
        },
      });
    } else if (loginMethod === LoginMethods.ETHEREUM) {
      privateKeyProvider = new EthereumPrivateKeyProvider({
        config: {
          chainConfig: sepoliaChainConfig,
        },
      });
    } else if (loginMethod === LoginMethods.SOLANA) {
      privateKeyProvider = new SolanaPrivateKeyProvider({
        config: {
          chainConfig: solanaChainConfig,
        },
      });
    }

    web3auth = new Web3Auth({
      clientId,
      uiConfig: {
        appName: "TripQ",
        theme: {
          primary: "darkblue",
        },
        mode: "dark",
        displayErrorsOnModal: true,
        defaultLanguage: "en",
        loginGridCol: 3,
        primaryButton: "externalLogin",
        uxMode: UX_MODE.REDIRECT,
      },
      web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
      privateKeyProvider: privateKeyProvider,
    });

    // Open login adapter
    const openloginAdapter = new OpenloginAdapter({
      loginSettings: {
        mfaLevel: "none",
      },
      adapterSettings: {
        uxMode: "redirect",
        whiteLabel: {
          defaultLanguage: "en",
        },
      },
    });

    // Configuring external wallets
    web3auth.modalConfig.adapters = [];
    if (loginMethod === LoginMethods.XRPL) {
      const customXrplAdapter = new CustomXrplAdapter();
      web3auth.configureAdapter(customXrplAdapter);
    } else if (loginMethod === LoginMethods.ETHEREUM) {
      const metamaskAdapter = new MetamaskAdapter({
        clientId,
        chainConfig: sepoliaChainConfig,
      });
      web3auth.configureAdapter(metamaskAdapter);
    }
    web3auth.configureAdapter(openloginAdapter);
    await web3auth.initModal();
    console.log("Web3Auth initialized successfully.");
    return web3auth;
  } catch (error) {
    console.error("Error initializing Web3Auth:", error);
  }
};

export const Login = async () => {
  if (!web3auth) {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginNetwork));
  }

  try {
    console.log("Attempting to log in...");

    if (web3auth) {
      //store.dispatch(loginSuccessfully())
      //localStorage.setItem("isLoggedIn", true)
      const res = await web3auth.connect();
      return res;
    }
  } catch (error) {
    localStorage.clear();
    throw error;
  }
};

export const Logout = async () => {
  const loginMethod = localStorage.getItem(LocalStorageKeys.LoginMethod);
  try {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginNetwork));
    if (loginMethod === LoginMethods.XRPL) {
      await xummLogout();
    } else {
      if (web3auth && web3auth.isConnected) {
        await web3auth.logout();
      }
    }
    toast.success("Logged out successfully");
    localStorage.clear();
    store.dispatch(logoutSuccessfully());
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

export const authenticateUser = async () => {
  if (!web3auth) {
    console.log("Web3Auth not initialized yet");
    return;
  }
  try {
    console.log("Authenticating user account...", web3auth.provider);

    const idToken = await web3auth.authenticateUser();
    console.log("ID Token:", idToken);
  } catch (error) {
    console.error("Error during authentication:", error);
  }
};

export const getUserInfo = async () => {
  if (!web3auth) {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginNetwork));
  }
  try {
    const user = await web3auth.getUserInfo();
    console.log("User Info:", user);
    return user;
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};

export const getAccounts = async () => {
  if (!web3auth || !web3auth.provider) {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginNetwork));
  }

  try {
    const accounts = await web3auth.provider.request({
      method: "xrpl_getAccounts",
    });
    return accounts;
  } catch (error) {
    throw error;
  }
};

export const getBalance = async (accountAddress) => {
  return await xrplClient.getXrpBalance(accountAddress);
};

export const sendTransaction = async (
  sourceAddress,
  destinationAddress,
  price,
  finishAfterTime,
  cancelAfterTime,
  transactionType
) => {
  try {
    const response = await showPayQRWindow(
      sourceAddress,
      destinationAddress,
      price,
      finishAfterTime,
      cancelAfterTime,
      DestinationTags.RESERVATION_PAYMENT,
      "XRP",
      null,
      transactionType,
      0
    );
    return response;
  } catch (error) {
    console.error("Error sending transaction:", error);
    throw error;
  }
};

async function getLastLedgerSequence() {
  if (!xrplClient.isConnected()) {
    await connectClient();
  }
  const ledger = await xrplClient.request({
    command: "ledger",
    ledger_index: "validated",
  });
  return ledger.result.ledger_index + 50; // Add a reasonable buffer to the current ledger index
}

async function getAccountSequence(account) {
  if (!xrplClient.isConnected()) {
    await connectClient();
  }
  const accountInfo = await xrplClient.request({
    command: "account_info",
    account: account,
  });
  return accountInfo.result.account_data.Sequence;
}

export const createTrustLineSocial = async (userAccountAddress) => {
  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginNetwork)
  );
  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }
  const lastLedgerSequence = await getLastLedgerSequence();
  const currency = process.env.REACT_APP_TPQ_CURRENCY_NAME;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const trustLineLimit = process.env.REACT_APP_TPQ_TRUSTLINE_LIMIT;

  if (!xrplClient.isConnected()) {
    await connectClient();
  }

  const tx = {
    TransactionType: "TrustSet",
    LimitAmount: {
      currency: currency,
      issuer: issuerAddress,
      value: trustLineLimit,
    },
    Account: userAccountAddress,
    LastLedgerSequence: lastLedgerSequence,
  };

  const result = await web3auth.provider.request({
    method: "xrpl_submitTransaction",
    params: {
      transaction: tx,
    },
  });

  if (result.result.engine_result === PaymentResults.tesSUCCESS) {
    console.log("Trustline initiated successfully");
    return true;
  } else {
    console.error("Trustline initiation failed");
    return false;
  }
};

export const reduceBalanceFromSOCIAL = async () => {
  const TPQ_REDUCE_AMOUNT = "10";
  const destinationAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;
  const sourceAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const lastLedgerSequence = await getLastLedgerSequence();
  const accountSequence = await getAccountSequence(sourceAddress);
  const currency = process.env.REACT_APP_TPQ_CURRENCY_NAME;
  const issuerAddress = process.env.REACT_APP_TPQ_ISSUER_ADDRESS;

  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginNetwork)
  );

  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }

  const tx = {
    TransactionType: "Payment",
    Amount: {
      currency: currency,
      issuer: issuerAddress,
      value: TPQ_REDUCE_AMOUNT,
    },
    Account: sourceAddress,
    Destination: destinationAddress,
    LastLedgerSequence: lastLedgerSequence,
  };

  const result = await web3auth.provider.request({
    method: "xrpl_submitTransaction",
    params: {
      transaction: tx,
    },
  });

  return result;
};

export const checkAccountActivation = async (account) => {
  try {
    if (!xrplClient.isConnected()) {
      await xrplClient.connect();
    }

    try {
      const accountInfo = await xrplClient.request({
        command: "account_info",
        account: account,
      });
      return true;
    } catch (error) {
      console.log("Error checking account activation:", error);
      return false;
    }
  } catch (error) {
    console.log("Error checking account activation:", error);
    return false;
  }
};

export const makeAPaymentSocial = async (
  sourceAddress,
  destinationAddress,
  amount
) => {
  try {
    if (!web3auth) {
      await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginNetwork));
    }

    const lastLedgerSequence = await getLastLedgerSequence();

    const tx = {
      TransactionType: TransactionTypes.PAYMENT,
      Account: sourceAddress,
      Amount: xrpToDrops(amount),
      Destination: destinationAddress,
      LastLedgerSequence: lastLedgerSequence,
    };

    const payed_resp = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: tx,
      },
    });

    return payed_resp;
  } catch (error) {
    console.log("Error making a payment:", error);
    throw error;
  }
};

export const escrowCreateSocial = async (tx) => {
  try {
    let web3auth = await initWeb3Auth(
      localStorage.getItem(LocalStorageKeys.LoginNetwork)
    );

    if (!web3auth) {
      throw new Error("failed to initialize web3auth");
    }

    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: tx,
      },
    });
    const rippleTimeToIsoTime = (rippleTime) => {
      const unixTime = (rippleTime + 0x386d4380) * 1000;
      return new Date(unixTime).toISOString();
    };
    const userInfo = await web3auth.getUserInfo();
    const newObject = {
      sequence: originalResponse.result.tx_json.Sequence,
      issuedUserToken: userInfo.verifierId,
      finishAfterTime: rippleTimeToIsoTime(
        originalResponse.result.tx_json.FinishAfter
      ),
      cancelAfterTime: rippleTimeToIsoTime(
        originalResponse.result.tx_json.CancelAfter
      ),
      transactionResult: originalResponse.result.engine_result,
    };
    return newObject;
  } catch (error) {
    throw error;
  }
};

export const escrowFinishSocial = async (tx) => {
  try {
    let web3auth = await initWeb3Auth(
      localStorage.getItem(LocalStorageKeys.LoginNetwork)
    );
    if (!web3auth) {
      throw new Error("failed to initialize web3auth");
    }

    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: tx,
      },
    });

    const userInfo = await web3auth.getUserInfo();

    const newObject = {
      sequence: originalResponse.result.tx_json.Sequence,
      issuedUserToken: userInfo.verifierId,
      finishAfterTime: "",
      cancelAfterTime: "",
      transactionResult: originalResponse.result.engine_result,
    };

    return newObject;
  } catch (error) {
    throw error;
  }
};

export const mintNFT_SOCIAL = async (nftData) => {
  const wallerCreator = localStorage.getItem(LocalStorageKeys.AccountAddress);
  try {
    if (!web3auth) {
      await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginNetwork));
    }

    const previousNFTs = await getAccountNFTsFromLedger(wallerCreator);
    // console.log("Previous NFTs:", previousNFTs);

    const responseObj = await uploadJsonToFileBase(nftData);

    const tx = {
      TransactionType: TransactionTypes.NFTokenMint,
      Account: wallerCreator,
      NFTokenTaxon: 0,
      Flags: 9, // 9 - tfBurnable + tfTransferable
      URI: convertStringToHex(responseObj.url),
    };

    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: tx,
      },
    });
    console.log("=========> NFT minted successfully:", originalResponse);
    const newNFTs = await getAccountNFTsFromLedger(wallerCreator);
    // console.log("New NFTs:", newNFTs);

    // find the newly added NFT
    const newNFT = newNFTs?.find(
      (new_nft) =>
        !previousNFTs?.some(
          (prev_nft) => prev_nft.NFTokenID === new_nft.NFTokenID
        )
    );

    // console.log("New NFT:", newNFT);

    const returnResponse = {
      reservationCode: nftData.reservationCode,
      uri: responseObj.url,
      nftId: newNFT?.NFTokenID,
    };

    return returnResponse;
  } catch (error) {
    throw error;
  }
};

export const burnNFT_SOCIAL = async (nftId) => {
  if (!web3auth) {
    await initWeb3Auth(localStorage.getItem(LocalStorageKeys.LoginNetwork));
  }

  if (!xrplClient.isConnected()) {
    await connectClient();
  }
  const burnerWallet = localStorage.getItem(LocalStorageKeys.AccountAddress);

  const tx = {
    TransactionType: TransactionTypes.NFTokenBurn,
    Account: burnerWallet,
    NFTokenID: nftId,
  };

  const submittedRes = await web3auth.provider.request({
    method: "xrpl_submitTransaction",
    params: {
      transaction: tx,
    },
  });

  return submittedRes;
};

export const cancelEscrowForSocial = async (res) => {
  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginNetwork)
  );
  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }

  const ownerAccAddress = res.WalletAddress
    ? res.WalletAddress
    : res.CustomerWalletAddress;

  const accountAddress = res.WalletAddress
    ? res.WalletAddress
    : localStorage.getItem(LocalStorageKeys.AccountAddress);

  try {
    const lastLedgerSequence = await getLastLedgerSequence();
    const accountSequence = await getAccountSequence(accountAddress);

    const tx = {
      TransactionType: TransactionTypes.ESCROW_CANCEL,
      Account: accountAddress,
      Owner: ownerAccAddress,
      OfferSequence: res.EscrowSequence,
      Sequence: accountSequence,
      LastLedgerSequence: lastLedgerSequence + 4,
    };

    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: tx,
      },
    });
    return originalResponse;
  } catch (error) {
    console.log("Error cancelling escrow:", error);
    throw error;
  }
};

export const nftAcceptOfferSOCIAL = async (
  offerId,
  nftId,
  user_mode_common = false
) => {
  const nftService = new NFTService();
  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginNetwork)
  );
  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }

  const userAccountAddress = localStorage.getItem(
    LocalStorageKeys.AccountAddress
  );
  const tx = {
    TransactionType: TransactionTypes.NFTokenAcceptOffer,
    Account: userAccountAddress,
    NFTokenSellOffer: offerId,
  };

  console.log("Accept offer tx: ", tx);

  try {
    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: tx,
      },
    });
    console.log("=====================================>");
    console.log("NFT offer accepted successfully:", originalResponse);

    if (user_mode_common) {
      const acceptOfferResponse = await nftService.acceptSellOfferForCommonUser(
        {
          offerId: offerId,
          nftId: nftId,
          destination: userAccountAddress,
        }
      );
      console.log("=====================================>");
      console.log("NFT offer common user DB response:", acceptOfferResponse);
      return acceptOfferResponse;
    } else {
      const acceptOfferResponse =
        await nftService.acceptSellOfferForSpecificUser({
          offerId: offerId,
          nftId: nftId,
          destination: userAccountAddress,
        });
      console.log("=====================================>");
      console.log("NFT offer specific user DB response:", acceptOfferResponse);
      return acceptOfferResponse;
    }
  } catch (err) {
    console.log("Error transferring NFT ownership: ", err);
    throw err;
  }
};

export const getSellOffersForNFT_SOCIAL = async (nftId) => {
  try {
    const offers_exist_earlier_response = await xrplClient.request({
      command: "nft_sell_offers",
      nft_id: nftId,
    });

    // Return offers if they exist, otherwise return an empty array
    const result_res = offers_exist_earlier_response?.result?.offers || [];
    return result_res;
  } catch (err) {
    // Check if the error is the specific RippledError you're expecting
    if (err?.message.includes("The requested object was not found")) {
      console.log("No sell offers found for this NFT.");
      return []; // Return an empty array if no offers exist
    }

    // For any other error, rethrow it
    console.log("Error getting sell offers for NFT: ", err);
    throw err;
  }
};

export const transferNFTOwnershipSOCIAL = async (nftData, newOwner) => {
  const nftService = new NFTService();
  const accountAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const nfTokenId = nftData.NFTTokenId;

  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginNetwork)
  );
  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }

  if (!xrplClient.isConnected()) {
    await connectClient();
  }

  let transferTx = {
    TransactionType: TransactionTypes.NFTokenCreateOffer,
    Account: accountAddress,
    Destination: newOwner,
    NFTokenID: nfTokenId,
    Amount: xrpToDrops(0),
    Flags: 1, // 1 - tfSell - for transfer
  };

  try {
    // check already transfer offer created
    const transferAlreadyCreated =
      await nftService.checkAlreadyOwnershipTransferCreated({
        nftId: nftData.NFTTokenId,
        destination: newOwner,
      });

    console.log("Transfer already created: ", transferAlreadyCreated);

    if (transferAlreadyCreated) {
      return { message: 409 };
    }

    const offersExistEarlier = await getSellOffersForNFT_SOCIAL(nfTokenId);

    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: transferTx,
      },
    });

    console.log("Ownership transfer response: ", originalResponse);
    const offersExistAfter = await getSellOffersForNFT_SOCIAL(
      originalResponse.result.tx_json.NFTokenID
    );

    // find newly added offer
    const newOffer = offersExistAfter.find(
      (offerAfter) =>
        !offersExistEarlier.some(
          (offerEarlier) =>
            offerEarlier.nft_offer_index === offerAfter.nft_offer_index
        )
    );
    const offerId = newOffer?.nft_offer_index;

    const obj = {
      nftId: originalResponse.result.tx_json.NFTokenID,
      OfferPrice: 0,
      offerDestination: newOwner,
      offerId: offerId,
      createdBy: accountAddress,
    };
    const result = await nftService.addSellOfferDataWithDestination(obj);
    console.log("Ownership transfer result add DB: ", result);
    return result;
  } catch (err) {
    console.log("Error transferring NFT ownership: ", err);
    throw err;
  }
};

export const createNFTSellOfferSOCIAL = async (nftData, value) => {
  const nftService = new NFTService();
  const accountAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  const nfTokenId = nftData.NFTTokenId;

  if (!value || value <= 0 || isNaN(value) || value === "") {
    throw new Error("Invalid offer price");
  }
  if (!nftData) {
    throw new Error("Invalid NFT data");
  }
  if (!accountAddress) {
    throw new Error("Account address not found");
  }

  let sellOfferTx = {
    TransactionType: TransactionTypes.NFTokenCreateOffer,
    Account: accountAddress,
    NFTokenID: nfTokenId,
    Amount: xrpToDrops(value),
    Flags: 1, // 1 - tfSell - for sell offer
  };

  try {
    let web3auth = await initWeb3Auth(
      localStorage.getItem(LocalStorageKeys.LoginNetwork)
    );
    if (!web3auth) {
      throw new Error("failed to initialize web3auth");
    }

    if (!xrplClient.isConnected()) {
      await connectClient();
    }

    const offersExistEarlier = await getSellOffersForNFT_SOCIAL(nfTokenId);

    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: sellOfferTx,
      },
    });

    const offersExistAfter = await getSellOffersForNFT_SOCIAL(
      originalResponse.result.tx_json.NFTokenID
    );

    const newOffer = offersExistAfter.find(
      (offerAfter) =>
        !offersExistEarlier.some(
          (offerEarlier) =>
            offerEarlier.nft_offer_index === offerAfter.nft_offer_index
        )
    );
    const offerId = newOffer?.nft_offer_index;

    console.log("====> Sell offer created successfully:", originalResponse);
    console.log("====> new offer: ", newOffer);

    const obj = {
      nftId: originalResponse.result.tx_json.NFTokenID,
      offerPrice: value,
      offerId: offerId,
      createdBy: accountAddress,
    };

    console.log("====> going update data in the DB: ", obj);
    const result = await nftService.addSellOfferDataWithoutDestination(obj);
    return result;
  } catch (err) {
    throw err;
  }
};

export const cancelOfferSOCIAL = async (offerId, nfTokenId) => {
  const nftService = new NFTService();
  const accountAddress = localStorage.getItem(LocalStorageKeys.AccountAddress);
  if (!offerId) {
    throw new Error("Invalid offer ID");
  }

  if (!accountAddress) {
    throw new Error("Account address not found");
  }

  let web3auth = await initWeb3Auth(
    localStorage.getItem(LocalStorageKeys.LoginNetwork)
  );

  if (!web3auth) {
    throw new Error("failed to initialize web3auth");
  }

  const cancelOfferTx = {
    TransactionType: TransactionTypes.NFTokenCancelOffer,
    Account: accountAddress,
    NFTokenOffers: [offerId], // The list of offer IDs to cancel
  };

  console.log("Cancel NFT offer transaction: ", cancelOfferTx);

  try {
    const originalResponse = await web3auth.provider.request({
      method: "xrpl_submitTransaction",
      params: {
        transaction: cancelOfferTx,
      },
    });
    console.log("====> Cancel offer response: ", originalResponse);
    if (originalResponse?.result?.engine_result === PaymentResults.tesSUCCESS) {
      const obj = {
        offerId: offerId,
        nftId: nfTokenId,
      };
      const response = await nftService.removeSellOffer(obj);
      console.log("====> Cancel offer response DB: ", response);
    }
  } catch (err) {
    console.log("Error transferring NFT ownership: ", err);
    throw err;
  }
};
