import axios from 'axios'; 

export default class OpenAIAssistantService {
    static instance = OpenAIAssistantService.instance || new OpenAIAssistantService();

    baseUrl = 'https://tripq-webapi.azurewebsites.net/';

    /**
     * Creates a new thread with the OpenAI Assistant.
     * @returns {Promise<Object>} The created thread object.
     */
    async createThread() {
        try {
            const response = await axios.post(`${this.baseUrl}/openai/threads`);
            return response.data;
        } catch (error) {
            console.error("Error creating thread:", error);
            throw error;
        }
    }

    /**
     * Adds a message to a thread.
     * @param {string} threadId - The ID of the thread.
     * @param {string} message - The message to add.
     * @returns {Promise<Object>} The response from the API.
     */
    async addMessage(threadId, message, context) {
        await new Promise(resolve => setTimeout(resolve, 5000));
        try {
            const response = await axios.post(`${this.baseUrl}/openai/threads/${threadId}/messages`, {
                content: JSON.stringify({
                    message: message,
                    context: context
                }),
            });
            return response.data;
        } catch (error) {
            console.error("Error adding message:", error);
            throw error;
        }
    }

    /**
     * Runs the assistant on a thread.
     * @param {string} threadId - The ID of the thread.
     * @returns {Promise<Object>} The response from the API.
     */
    async runAssistant(threadId) {
        await new Promise(resolve => setTimeout(resolve, 5000));
        try {
            const response = await axios.post(`${this.baseUrl}/openai/threads/${threadId}/runs`);
            return response.data;
        } catch (error) {
            console.error("Error running assistant:", error);
            throw error;
        }
    }

    /**
     * Retrieves messages from a thread.
     * @param {string} threadId - The ID of the thread.
     * @returns {Promise<Object>} The list of messages.
     */
    async getMessages(threadId) {
        await new Promise(resolve => setTimeout(resolve, 5000));
        try {
            const response = await axios.get(`${this.baseUrl}/openai/threads/${threadId}/messages`);
            return response.data;
        } catch (error) {
            console.error("Error retrieving messages:", error);
            throw error;
        }
    }

     /**
     * Check run status from a thread.
     * @param {string} threadId - The ID of the thread.
     * @param {string} runId - The ID of the run.
     * @returns {Promise<Object>} The list of messages.
     */
        async getRunStatus(threadId, runId) {
            await new Promise(resolve => setTimeout(resolve, 5000));
            console.log(`Checking run ${runId} from thread ${threadId}...`);
            try {
                const response = await axios.get(`${this.baseUrl}/openai/threads/${threadId}/runs/${runId}`);
                return response.data;
            } catch (error) {
                console.error("Error retrieving run status:", error);
                throw error;
            }
        }

        async submitFunctionResponse(threadId, runId, functionOutput, callId) {
            await new Promise(resolve => setTimeout(resolve, 5000));
            try{
                const response = await axios.post(`${this.baseUrl}/openai/threads/${threadId}/runs/${runId}/submit_tool_outputs`, {
                    tool_call_id: callId,
                    output: functionOutput,
                });
            } catch (error) {
                console.error("Error submitting function response:", error);
                throw error;
            }  
        }   
}