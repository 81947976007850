import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { ChatDots, ArrowsFullscreen, ArrowsCollapse } from "react-bootstrap-icons";
import OpenAIAssistantService from "../../services-domain/openai-assistant-service";
import "./styles.scss";
import { toast } from "react-hot-toast";
import HotelService from "../../services-domain/hotel-service";
import { useDispatch } from "react-redux";
import { setCustomerDetails } from "../../redux/Chatbot/MakeReservationStateSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchText } from "../../redux/Chatbot/SearchTextStateSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faVolumeUp, faVolumeMute,faMinus } from "@fortawesome/free-solid-svg-icons";
import { FaPaperPlane } from "react-icons/fa";
import SpeechService from "../../services-common/speech-service";
import { setShowChat } from "../../redux/Chatbot/ShowChatStateSlice";


const assistantService = OpenAIAssistantService.instance;

const Chatbot = () => {
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState(localStorage.getItem('threadId') || '');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);

  const hotelService = HotelService.instance;
  let customerDetails = useSelector((state) => state.MakeReservationState);
  let chatContext = useSelector((state) => state.ChatContextState);
  const dispatch = useDispatch();
  const speechService = new SpeechService();
  const showChat = useSelector((state) => state.ShowChatState.showChat);

  useEffect(() => {
    if (!threadId) {
        handleCreateThread();
    }
}, []); // Runs only once when the component mounts

useEffect(() => {
    if (threadId) {
        handleGetMessages(threadId);
    }
}, [threadId]); // Runs whenever threadId is updated

  const retryOperation = async (operation, retries = 3) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        if (attempt > 1) {
          setTimeout(200); //wait 200ms before retrying
        }
        return await operation();
      } catch (error) {
        if (attempt === retries) throw error;
      }
    }
  };

  const handleCreateThread = async () => {
    try {
      setIsLoading(true);
      setStatus('Creating thread...');
      const result = await retryOperation(() => assistantService.createThread());
      setThreadId(result.id);
      localStorage.setItem('threadId', result.id);
      setStatus('Thread created successfully!');
      const welcomeMsg = { role: 'assistant', content: 'Welcome to TripQ! How can I help you today?' };
      setMessages([welcomeMsg]);
      setIsLoading(false);
    } catch (error) {
      setStatus('Error creating thread: ' + error.message);
      setIsLoading(false);
    }
  };

  const handleAddMessage = async () => {
    try {
      if (!message.trim()) return;

      setIsLoading(true);
      setStatus('Adding message...');
      setMessage('');
      
      const userMessage = { role: 'user', content: message };
      setMessages(prevMessages => [userMessage, ...prevMessages]);

      const currentMessage = message;

      setMessage('');
      const textarea = document.querySelector(".custom-textarea");
      if (textarea) {
        textarea.style.height = "auto";
      }

      const chatbotContext = chatContext ? { ...chatContext } : {}; 
      const currentDate = new Date();
      const currentDateFormatted = `${String(currentDate.getDate()).padStart(2, '0')}.${String(currentDate.getMonth() + 1).padStart(2, '0')}.${currentDate.getFullYear()}`;
      chatbotContext.currentDate = currentDateFormatted;

      await retryOperation(() => assistantService.addMessage(threadId, currentMessage, chatbotContext));
      setStatus('Message added successfully!');
      await handleRunAssistant();
    } catch (error) {
      setStatus('Error adding message: ' + error.message);
      setIsLoading(false);
    }
  };

  const handleRunAssistant = async () => {
    try {
      setStatus('Running assistant...');
      let result = await retryOperation(() => assistantService.runAssistant(threadId));
      let runId = result.id;

      const checkRunStatus = async () => {
        const statusResult = await handleGetRunStatus(runId);

        if (statusResult.status === "in_progress" || statusResult.status === "queued") {
          setTimeout(checkRunStatus, 200); 
        }else if (["failed", "cancelled"].includes(statusResult.status)) {
          setStatus(`Run failed or cancelled: ${statusResult.status}`);
          setIsLoading(false);
          return;
        }else if (statusResult.status === "completed") {
          setStatus('Assistant run successfully!');
          await handleGetMessages();
          setIsLoading(false);
        } else if (statusResult.status === "requires_action") {
          setStatus('Action required...');
          const toolCalls = statusResult.required_action.submit_tool_outputs.tool_calls;
          const functionName = toolCalls[0].function.name;
          const callId = toolCalls[0].id;
          const data = JSON.parse(toolCalls[0].function.arguments);

          if (functionName) {
            await handleFunctionCall(functionName, runId, callId, data);
          }
        } else {
          setStatus(`Run failed with status: ${statusResult.status}`);
          setIsLoading(false);
        }
      };

      setTimeout(checkRunStatus, 200);
    } catch (error) {
      setStatus('Error running assistant: ' + error.message);
      setIsLoading(false);
    }
  };

  const handleFunctionCall = async (functionName, runId, callId, data) => {
    try {
      setStatus(`Calling function: ${functionName}...`);
      let functionOutput = null;

      if (functionName === "add_room_type") {
        const res = await hotelService.createRoom(data);
        if (res > 0) {
          try {
            functionOutput = "Room type added successfully!";
          } catch (error) {
            console.error("Error adding room type:", error);
            throw error;
          }
          toast.success("Room type created successfully!", {
            duration: 10000,
          });
        }
      } else if (functionName === "add_reservation") {
        let customerData = {
          firstName: data.FirstName,
          lastName: data.LastName,
          email: data.Email,
          telephone: data.Telephone,
          roomTypes: data.RoomTypes,
          noOfRooms: data.NoOfRooms,
        };
        dispatch(setCustomerDetails(customerData));
        try {
          functionOutput = "Reservation will be continued after your payment successfully.";
        } catch (error) {
          console.error("Error adding reservation:", error);
          throw error;
        }
      }
      else if (functionName === "search_hotels_text") {
        const bookingDetails = {
          GuestCount: data.GuestCount,
          City: data.City,
          CheckinDate: data.CheckinDate,
          CheckoutDate: data.CheckoutDate,
          Facilities: data.Facilities,
        };
        const sentence = `Hotels for ${bookingDetails.GuestCount} guests in ${bookingDetails.City} from ${bookingDetails.CheckinDate} to ${bookingDetails.CheckoutDate}  with ${bookingDetails.Facilities ? bookingDetails.Facilities : 'any facilities'}.`;
        dispatch(setSearchText(sentence));
        try {
          functionOutput = "You can check the availabality of hotels and select one of them to make a reservation.Then I can help you to make a reservation.";
        } catch (error) {
          console.error("Error creating hotel search text:", error);
          throw error;
        }
      }
      else {
        setStatus(`Unknown function: ${functionName}`);
        setIsLoading(false);
        return;
      }

      // Submit function response to the SAME run
      await assistantService.submitFunctionResponse(threadId, runId, functionOutput, callId);
      setStatus("Function response submitted. Waiting for assistant...");

      // Continue checking the SAME run after submitting the function outputs
      setTimeout(() => {
        const checkRunStatus = async () => {
          const statusResult = await handleGetRunStatus(runId);

          if (statusResult.status === "in_progress" || statusResult.status === "queued") {
            setTimeout(checkRunStatus, 200); // Wait and check again
          } else if (statusResult.status === "completed") {
            setStatus('Assistant run successfully!');
            await handleGetMessages();
            setIsLoading(false);
          } else if (statusResult.status === "requires_action") {
            setStatus('Action required...');
            const toolCalls = statusResult.required_action.submit_tool_outputs.tool_calls;
            const nextFunctionName = toolCalls[0].function.name;
            const nextCallId = toolCalls[0].id;
            const nextData = JSON.parse(toolCalls[0].function.arguments);

            if (nextFunctionName) {
              await handleFunctionCall(nextFunctionName, runId, nextCallId, nextData);
            }
          } else {
            setStatus(`Run failed with status: ${statusResult.status}`);
            console.log("failed:", statusResult.last_error.message);
            toast.error("Rate limit exceeded, Try again later")
            setIsLoading(false);
          }
        };

        checkRunStatus();
      }, 200);
    } catch (error) {
      setStatus('Error executing function call: ' + error);
      setIsLoading(false);
    }
  };

  const handleGetRunStatus = async (runId) => {
    try {
      setStatus('Retrieving run status...');
      const result = await retryOperation(() => assistantService.getRunStatus(threadId, runId));
      return result;
    } catch (error) {
      setStatus('Error retrieving run status: ' + error.message);
      throw error;
    }
  };

  const handleGetMessages = async () => {
    try {
      setStatus('Retrieving messages...');
      const result = await retryOperation(() => assistantService.getMessages(threadId));
      const messagesContent = result.data;
      if (!messagesContent || messagesContent.length === 0) {
        const welcomeMsg = { role: 'assistant', content: 'Welcome to TripQ! How can I help you today?' };
        handleTextToSpeech(welcomeMsg.content);
        setMessages([welcomeMsg]);
      } else {
        setMessages(messagesContent);
        if (messagesContent[0].role === 'assistant') {
          handleTextToSpeech(messagesContent[0].content[0].text.value);
        }
      }
      setStatus('Messages retrieved successfully!');
    } catch (error) {
      setStatus('Error retrieving messages: ' + error.message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddMessage();
    }
  };

  async function handleSpeechToTextFromMic() {
    setMessage("");
    setIsListening(true);
    speechService
      .speechToTextFromMic()
      .then((displayText) => {
        setMessage(displayText);
        const textarea = document.querySelector(".custom-textarea");
        if (textarea) {
          textarea.value = displayText;
          textarea.style.height = "auto";
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
        setIsListening(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsListening(false);
      });
  }

  async function handleTextToSpeech(text) {
    if (!isSoundEnabled || !showChat) return;
    try {
      await speechService.textToSpeech(text);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const toggleSound = () => {
    setIsSoundEnabled((prev) => !prev);
  };

  return (
    <div>
      {!showChat && (
        <button
          className="chatbot-button"
          onClick={() => dispatch(setShowChat(true))}
          aria-label="Open chat"
          title="TripQ Chatbot"
        >
          <ChatDots size={24} className="chat-dots-icon" />
        </button>
      )}
      {showChat && (
        <div className={`chatbot-window`} role="dialog" aria-labelledby="chatbot-header">
            <div className="d-flex justify-content-between align-items-center mt-2 ms-2 me-2" id="chatbot-header">
              <strong>TripQ Chatbot 🤖 </strong>
              <div>
                <button
                  onClick={toggleSound}
                  aria-label={isSoundEnabled ? "Mute Sound" : "Enable Sound"}
                  className="sound-btn"
                >
                  {isSoundEnabled ? <FontAwesomeIcon icon={faVolumeUp} size="lg"/> : <FontAwesomeIcon icon={faVolumeMute} size="lg"/>}
                </button>
                <button
                  className="close-btn"
                  onClick={() => dispatch(setShowChat(false))}
                  aria-label="Close chat"
                >
                  <FontAwesomeIcon icon={faMinus} size="lg" />
                </button>
              </div>
            </div>

        
              <div className="chat-window">
                <div className="messages">
                  {messages.slice().map((msg, index) => {
                    let messageText = '';
                    if (typeof msg.content === 'string') {
                      messageText = msg.content;
                    } else if (msg.role === 'user' && msg.content[0]?.text?.value) {
                      try {
                        // Parse the JSON string
                        const parsedContent = JSON.parse(msg.content[0].text.value);
                        // Extract only the message
                        messageText = parsedContent.message;
                      } catch (e) {
                        // Fallback in case parsing fails
                        messageText = msg.content[0]?.text?.value;
                      }
                    } else {
                      // For assistant messages or other formats
                      messageText = msg.content[0]?.text?.value || msg.content.message;
                    }

                    return (
                      <div key={index} className={`message ${msg.role === 'user' ? 'user' : 'assistant'}`}>
                        {messageText}
                      </div>
                    );
                  })}

                </div>
                <Form onSubmit={(e) => { e.preventDefault(); handleAddMessage(); }}>
                  <div className="input-area">
                    <Form.Control
                      as="textarea"
                      placeholder={isListening?"Listening...":"Type your message..."}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={handleKeyPress}
                      disabled={isLoading}
                      rows={1} // Starts with 1 row
                      className="custom-textarea"
                      onInput={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = `${e.target.scrollHeight}px`;
                      }}
                    />
                    <div className="mic-send-btns">
                      <Button
                        variant="secondary"
                        id="button-addon1"
                        className={`btn-sm ${isListening
                          ? "chatbot-microphone-icon-isListening remove-right-border-radius"
                          : "chatbot-microphone-icon remove-right-border-radius"
                          }`}
                        onClick={handleSpeechToTextFromMic}
                        title="Speak freely in your native tongue"
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faMicrophone}
                          className=""
                        />
                      </Button>
                      <Button
                        type="submit"
                        disabled={isLoading || !message.trim()}
                        className="btn-sm remove-left-border-radius"
                      >
                        {isLoading ? <div className="spinner"></div> : <FaPaperPlane
                          size={15}
                          className=""
                          title="Send message"
                        />}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;